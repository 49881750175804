<template>
  <v-container fluid>
    <Menu
      style="position:fixed; height: 50px; z-index: 12;margin-top:-12px; background: #DBC7FF; left: 0; right: 0;"
    />
    <div
      style="background: #F4F9FF; height: 100%; margin:0px; padding-top:50px; min-height: calc(100vh - 144px)"
      class="d-flex flex-row"
    >
      <client-info-card
        :id="this.$route.params.id"
        :onDeleted="onDeleted"
        :onIntake="onIntake"
        ref="infoCard"
      />
      <div style="width: 100%; height: calc(100vh - 145px);">
        <v-card-title>
          <label class="item_title">Client Checked Ins</label>
        </v-card-title>
        <v-card-subtitle class="mt-2">
          <label class="item_description">
            Please manage the client checked ins information:
          </label>
        </v-card-subtitle>
        <v-card-text class="d-flex">
          <div>
            <VueCtkDateTimePicker
              id="newCheckedInDate"
              v-model="date"
              format="MM/DD/YYYY hh:mm a"
              formatted="MM/DD/YYYY hh:mm a"
            />
          </div>
          <div>
            <v-select
              label="Families"
              flat
              outlined
              dense
              multiple
              v-model="selectedFamily"
              :items="familyArray"
              class="mb-2 ml-5"
              style="font-family:'Poppins-Regular';"
              item-text="name"
              item-value="index"
              hide-details
            />
          </div>
          <v-textarea
            v-model="note"
            label="Note"
            flat
            outlined
            dense
            rows="2"
            auto-grow
            hide-details
            class="ml-5"
          />
          <v-btn rounded color="primary" class="ml-5" @click="onCheckedIn"
            >Check In <v-icon class="ml-2">mdi-check</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="position-relative">
          <div
            style="position: absolute; bottom: 35px; left: 35px"
            v-if="average_time"
          >
            Average time: {{ average_time }}
          </div>
          <v-data-table
            :items="data"
            :headers="headers"
            :loading="loading"
            class="checked-in-table"
          >
            <template v-slot:[`item.date`]="{ item }">
              <div v-if="item.editing">
                <VueCtkDateTimePicker
                  v-model="item.editingDate"
                  format="MM/DD/YYYY hh:mm a"
                  formatted="MM/DD/YYYY hh:mm a"
                  :id="item._id"
                />
              </div>
              <div v-else>
                {{ formatDate(item.date) }}
              </div>
            </template>
            <template v-slot:[`item.families`]="{ item }">
              <div v-if="item.editing">
                <v-select
                  label="Families"
                  flat
                  outlined
                  dense
                  multiple
                  v-model="item.editingFamilies"
                  :items="familyArray"
                  class="mb-2 ml-5"
                  style="font-family:'Poppins-Regular';"
                  item-text="name"
                  item-value="index"
                  hide-details
                />
              </div>
              <div v-else>
                {{ item.families.map((f) => f.name).join(", ") }}
              </div>
            </template>
            <template v-slot:[`item.note`]="{ item }">
              <v-textarea
                v-model="item.editingNote"
                label="Note"
                flat
                outlined
                dense
                rows="2"
                auto-grow
                hide-details
                class="ml-5 my-2"
                v-if="item.editing"
              />
              <div class="text-block" v-else>
                {{ item.note }}
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div style="width: 120px;">
                <v-tooltip bottom v-if="item.editing">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="onDoneCheckedIn(item)"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-check</v-icon></v-btn
                    >
                  </template>
                  Done
                </v-tooltip>
                <v-tooltip bottom v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="onEditCheckedIn(item)"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                  </template>
                  Edit
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="onDeleteCheckedIn(item)"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </template>
                  Delete
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </div>
      <!-- <div v-for="checked_in in data" :key="checked_in">
          {{ formatDate(checked_in) }}
        </div> -->
      <v-snackbar v-model="snackbar">
        {{ snackbarMessage }}
      </v-snackbar>
      <confirm-delete-dialog
        :deleteDialog="deleteDialog"
        :onClose="onCloseConfirmDelete"
        :onConfirm="onConfirmDelete"
      />
    </div>
  </v-container>
</template>
<script>
import dateFormat from "dateformat";
import { mapActions, mapGetters } from "vuex";
import ConfirmDeleteDialog from "@/components/ConfirmDeleteDialog.vue";
import ClientInfoCard from "@/components/client/ClientInfoCard.vue";
import Menu from "@/components/core/Menu.vue";
export default {
  components: { ConfirmDeleteDialog, ClientInfoCard, Menu },
  data() {
    return {
      loading: false,
      date: null,
      data: [],
      families: {},
      familyArray: [],
      selectedFamily: [],
      note: "",
      snackbar: false,
      snackbarMessage: null,
      headers: [
        { text: "Date", value: "date" },
        { text: "Families", value: "families" },
        { text: "Note", value: "note" },
        { text: "Actions", value: "actions" },
      ],
      deleteDialog: false,
      selected: null,
    };
  },
  methods: {
    ...mapActions({
      addCheckedIn: "checkedin/addCheckedIn",
      fetchAllCheckedIns: "checkedin/fetchAllCheckedIns",
      oldCheckedInData: "checkedin/oldCheckedInData",
      editCheckedIn: "checkedin/editCheckedIn",
      deleteCheckedIn: "checkedin/deleteCheckedIn",
      getAllQuestions: "question/fetchAllQuestions",
      getAllIntakes: "intake/fetchAllIntakes",
      setSelectedId: "intake/setSelectedId",
      getClient: "client/getClient",
    }),
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
    formatDate(dateStr) {
      var date = new Date(dateStr);
      return dateFormat(this.convertTZ(date, ""), "mm/dd/yyyy hh:MM TT");
    },
    onCheckedIn() {
      var selectedFamilyDataArray = this.selectedFamily.map(
        (i) => this.families[i]
      );
      if (!selectedFamilyDataArray.length) {
        this.showSnackbar("Please select the visited family members");
        return;
      }
      this.addCheckedIn({
        families: selectedFamilyDataArray,
        note: this.note,
        client_id: this.$route.params.id,
        date: this.date,
      })
        .then((res) => {
          if (!this.data.some((item) => item._id === res._id)) {
            this.data.unshift(res);
            this.data.sort((a, b) => new Date(b.date) - new Date(a.date));
          }
          this.$refs.infoCard.getData();
          this.selectedFamily = [];
          this.note = "";
          this.date = null;

          //Close tab
          window.close();
        })
        .catch((error) => {
          if (error.response) {
            this.showSnackbar(error.response.data.message);
          } else {
            this.showSnackbar(error);
          }
        });
    },
    onDoneCheckedIn(item) {
      // this.data = this.data.map((d) =>
      //   d._id == item._id ? { ...d, editing: false } : d
      // );
      var selectedFamilyDataArray = item.editingFamilies.map(
        (i) => this.families[i]
      );
      if (!selectedFamilyDataArray.length) {
        this.showSnackbar("Please select the visited family members");
        return;
      }
      this.editCheckedIn({
        _id: item._id,
        families: selectedFamilyDataArray,
        note: item.editingNote,
        client_id: this.$route.params.id,
        date: item.editingDate,
      })
        .then((res) => {
          this.data = this.data.map((d) => (d._id == res._id ? res : d));
          this.data.sort((a, b) => new Date(b.date) - new Date(a.date));
          this.$refs.infoCard.getData();
        })
        .catch((error) => {
          if (error.response) {
            this.showSnackbar(error.response.data.message);
          } else {
            this.showSnackbar(error);
          }
        });
    },
    onEditCheckedIn(item) {
      this.data = this.data.map((d) =>
        d._id == item._id
          ? {
              ...d,
              editing: true,
              editingDate: this.formatDate(d.date),
              editingFamilies: item.families.map((f) => f.index),
              editingNote: item.note,
            }
          : d
      );
    },
    onConfirmDelete() {
      this.deleteDialog = false;
      this.deleteCheckedIn({ _id: this.selected._id })
        .then((res) => {
          this.data = this.data.filter((d) => d._id != res._id);
          this.$refs.infoCard.getData();
        })
        .catch((error) => {
          if (error.response) {
            this.showSnackbar(error.response.data.message);
          } else {
            this.showSnackbar(error);
          }
        });
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
    },
    onDeleteCheckedIn(item) {
      this.deleteDialog = true;
      this.selected = item;
    },
    showSnackbar(message) {
      this.snackbar = true;
      this.snackbarMessage = message;
    },
    onDeleted() {
      this.$router.back();
    },
    onIntake() {
      this.$router.push({
        name: "intake",
        params: { id: this.$route.params.id },
      });
    },
    analyzeData() {
      this.families = {};
      Object.keys(this.intakes).map((key) => {
        const intake = this.intakes[key];
        const question_id = key.split("_")[0];
        const index = key.split("_")[1];
        for (var i = 0; i < this.allQuestions.length; i++) {
          if (question_id == this.allQuestions[i]._id) {
            if (this.allQuestions[i].parent == "60adb8e8455d9faa10ae54ce") {
              if (!this.families[index]) {
                this.families[index] = { index };
              }
              this.families[index][this.allQuestions[i].key] = intake;
            }
          }
        }
      });
      this.familyArray = Object.values(this.families);
      this.selectedFamily = this.familyArray.map((f) => f.index);
      this.date = new Date().toLocaleString("en", {
        timeZone: "America/New_York",
      });

      //Check if there is old data, and add them
      this.getClient(this.$route.params.id).then((res) => {
        console.log(res.checked_in);
        if (res.checked_in && res.checked_in.length) {
          console.log("there is old data, adding them");
          this.oldCheckedInData({
            client_id: this.$route.params.id,
            families: this.familyArray,
          }).then((checked_in_res) => {
            this.data = checked_in_res;
          });
        } else {
          console.log("no old data");
        }
      });
    },
    getData() {
      if (!this.$route.params.id) return;
      this.setSelectedId(this.$route.params.id);
      this.loadingData = true;
      this.getAllQuestions()
        .then(() => {
          this.getAllIntakes()
            .then(() => {
              this.loadingData = false;
              this.analyzeData();
            })
            .catch((error) => {
              this.loadingData = false;
              if (error.response) {
                console.log(error.response.data.message);
                this.snackMessage = error.response.data.message;
                this.snackbar = true;
              } else {
                console.log(error);
              }
            });
        })
        .catch((error) => {
          this.loadingData = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      intakes: "intake/getAllIntakes",
      allQuestions: "question/getAllQuestions",
    }),
    average_time() {
      if (this.data.length < 2) return "";
      const intervals = [];
      for (let i = 0; i < this.data.length - 1; i++) {
        const interval =
          new Date(this.data[i + 1].date).getTime() -
          new Date(this.data[i].date).getTime();
        intervals.push(interval);
      }

      // Calculate average time interval in milliseconds
      const avgInterval = intervals.reduce((a, b) => a + b) / intervals.length;

      // Convert average interval to minutes
      const avgIntervalInHours = Math.abs(avgInterval / (1000 * 3600));

      console.log(avgIntervalInHours);
      if (avgIntervalInHours < 24) return "Less than 1 day";
      return (avgIntervalInHours / 24).toFixed(1) + " Days";
    },
  },
  mounted() {
    this.getData();
    this.loading = true;
    this.fetchAllCheckedIns({ _id: this.$route.params.id })
      .then((res) => {
        this.loading = false;
        this.data = res;
        console.log(res);
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.response.data.message);
      });
  },
};
</script>
<style scoped>
.checked-in-table >>> .v-data-table__wrapper {
  overflow: visible;
}
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
.notranslate {
  transform: none !important;
}
</style>
